<template>
  <div class="navBarMobile text-uppercase">
    <div class="container">
      <div class="row">
        <!-- icon mua ve -->
        <div class="col navBarMobileContent" :class="{ activeRoute: activeTab('/mua-ho-concept') }">
          <router-link class to="/mua-ho-concept" exact>
            <div class="w-100 navBarMobileLogo">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g transform="translate(-34 -681)">
                  <rect width="26" height="26" transform="translate(34 681)" fill="#ddd" opacity="0" />
                  <path
                    d="M-.984-14.31-9.214-7.531V-.214A.715.715,0,0,0-8.5.5l5-.013a.715.715,0,0,0,.711-.714V-4.5a.715.715,0,0,1,.714-.714H.785A.715.715,0,0,1,1.5-4.5V-.23s0,0,0,0a.715.715,0,0,0,.714.714h0l5,.014a.715.715,0,0,0,.714-.714V-7.536L-.3-14.31a.633.633,0,0,0-.342-.12A.633.633,0,0,0-.984-14.31Zm13,4.608L8.286-12.778v-6.183A.536.536,0,0,0,7.75-19.5H5.25a.536.536,0,0,0-.536.536v3.242l-4-3.288A2.49,2.49,0,0,0-.644-19.5a2.49,2.49,0,0,0-1.362.488L-13.306-9.7a.608.608,0,0,0-.194.413.622.622,0,0,0,.123.342l1.138,1.384a.607.607,0,0,0,.414.2.623.623,0,0,0,.341-.123l10.5-8.649a.633.633,0,0,1,.341-.121A.633.633,0,0,1-.3-16.14L10.2-7.491a.622.622,0,0,0,.342.123.608.608,0,0,0,.413-.194l1.138-1.384a.623.623,0,0,0,.122-.34A.607.607,0,0,0,12.018-9.7Z"
                    transform="translate(47.643 703.498)"
                    fill="#666"
                  />
                </g>
              </svg>
            </div>
            <div>
              <p>Trang chủ</p>
            </div>
          </router-link>
        </div>
        <!-- icon ket qua -->
        <!-- icon tai khoan -->
        <div
          v-if="loggedIn"
          class="col navBarMobileContent"
          :class="{ activeRoute: activeTab('/mua-ho-concept/tai-khoan-intro') }"
        >
          <router-link class to="/mua-ho-concept/tai-khoan-intro">
            <div class="w-100 navBarMobileLogo">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g transform="translate(19256 -7436)">
                  <rect width="26" height="26" transform="translate(-19256 7436)" fill="#fff" opacity="0" />
                  <g transform="translate(1.001 46)">
                    <path
                      d="M16.981,21.737H2.038A2.04,2.04,0,0,1,0,19.7V17.933a5.712,5.712,0,0,1,5.706-5.706h.708a7.389,7.389,0,0,0,6.19,0h.647a6,6,0,0,0,5.192,8.892A2.022,2.022,0,0,1,16.981,21.737ZM9.509,10.868a5.434,5.434,0,1,1,5.434-5.434A5.44,5.44,0,0,1,9.509,10.868Z"
                      transform="translate(-19256.002 7392)"
                      fill="#666"
                    />
                    <path
                      d="M5-12.031a5,5,0,0,0-5,5,5,5,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,5-12.031Zm.484,7.581v.323a.324.324,0,0,1-.323.323H4.839a.324.324,0,0,1-.323-.323v-.327a1.791,1.791,0,0,1-.935-.3.324.324,0,0,1-.046-.508l.242-.228a.327.327,0,0,1,.4-.032.784.784,0,0,0,.4.109h.907A.446.446,0,0,0,5.9-5.89a.471.471,0,0,0-.306-.458L4.133-6.789a1.439,1.439,0,0,1-.992-1.383,1.412,1.412,0,0,1,1.375-1.44v-.323a.324.324,0,0,1,.323-.323h.323a.324.324,0,0,1,.323.323v.327a1.791,1.791,0,0,1,.935.3.324.324,0,0,1,.046.508l-.242.228a.327.327,0,0,1-.4.032.784.784,0,0,0-.4-.109H4.518a.446.446,0,0,0-.413.472.471.471,0,0,0,.306.458l1.452.442A1.439,1.439,0,0,1,6.855-5.89,1.408,1.408,0,0,1,5.484-4.451Z"
                      transform="translate(-19242.492 7414.149)"
                      fill="#666"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <p>tài khoản</p>
            </div>
          </router-link>
        </div>
        <div
          v-else
          class="col navBarMobileContent"
          @click="hanlerLogin"
          :class="{ activeRoute: activeTab('/tai-khoan-intro') }"
        >
          <div class="navBarMobileContentModalLogin">
            <a class="pointer">
              <div class="w-100 navBarMobileLogo">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g transform="translate(19256 -7436)">
                    <rect width="26" height="26" transform="translate(-19256 7436)" fill="#fff" opacity="0" />
                    <g transform="translate(1.001 46)">
                      <path
                        d="M16.981,21.737H2.038A2.04,2.04,0,0,1,0,19.7V17.933a5.712,5.712,0,0,1,5.706-5.706h.708a7.389,7.389,0,0,0,6.19,0h.647a6,6,0,0,0,5.192,8.892A2.022,2.022,0,0,1,16.981,21.737ZM9.509,10.868a5.434,5.434,0,1,1,5.434-5.434A5.44,5.44,0,0,1,9.509,10.868Z"
                        transform="translate(-19256.002 7392)"
                        fill="#666"
                      />
                      <path
                        d="M5-12.031a5,5,0,0,0-5,5,5,5,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,5-12.031Zm.484,7.581v.323a.324.324,0,0,1-.323.323H4.839a.324.324,0,0,1-.323-.323v-.327a1.791,1.791,0,0,1-.935-.3.324.324,0,0,1-.046-.508l.242-.228a.327.327,0,0,1,.4-.032.784.784,0,0,0,.4.109h.907A.446.446,0,0,0,5.9-5.89a.471.471,0,0,0-.306-.458L4.133-6.789a1.439,1.439,0,0,1-.992-1.383,1.412,1.412,0,0,1,1.375-1.44v-.323a.324.324,0,0,1,.323-.323h.323a.324.324,0,0,1,.323.323v.327a1.791,1.791,0,0,1,.935.3.324.324,0,0,1,.046.508l-.242.228a.327.327,0,0,1-.4.032.784.784,0,0,0-.4-.109H4.518a.446.446,0,0,0-.413.472.471.471,0,0,0,.306.458l1.452.442A1.439,1.439,0,0,1,6.855-5.89,1.408,1.408,0,0,1,5.484-4.451Z"
                        transform="translate(-19242.492 7414.149)"
                        fill="#666"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div>
                <p>tài khoản</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isActiveResultTab: false
    };
  },
  components: {},
  beforeCreate() {},
  created() {},
  mounted() {},
  computed: {
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    }
  },
  methods: {
    activeTab(router) {
      return this.$route && this.$route.path === router;
    },
    hanlerLogin() {
      this.$store.commit('setRedirectAfterLogin', null);
      this.$store.commit('setModalLogin', true);
    }
  }
};
</script>
<style lang="scss" scoped>
.activeRoute {
  a {
    svg {
      path {
        fill: $primaryColor !important;
      }
    }

    p {
      color: $primaryColor !important;
    }
  }
}
</style>
