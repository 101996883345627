<template>
  <div>
    <IntroHeaderMobile />
    <transition name="fade" mode="out-in">
      <router-view class="layoutSubMain" :key="$route.path" />
    </transition>
    <IntroNavbar />
  </div>
</template>
<script>
import IntroHeaderMobile from '../components/Intro/Header/IntroHeaderMobile';
import IntroNavbar from '../components/Intro/Navbar/IntroNavbar';
export default {
  name: `MainApp`,
  components: {
    IntroHeaderMobile,
    IntroNavbar
  }
};
</script>
<style lang="scss">
// @import "../assets/sass/main";
</style>
